import React from 'react'
import PropTypes from 'prop-types'
import _variables from '../../scss/common/_variables.scss'

const TransparentButton = ({ label, onShowModal, btnwidth, order, prev }) => (
  <span
    onClick={onShowModal}
    className="transparentButton"
    style={{
      cursor: 'pointer',
      background: 'transparent',
      padding: '10px 20px',
      display: 'inline-block',
      color: '#fff',
      fontWeight: '600',
      textTransform: 'uppercase',
      width: btnwidth || 'auto',
      margin: '10px 0',
    }}
    state={{
      order_scroll: order || 1,
      previous_order: prev || 0,
    }}
  >
    {label}
  </span>
)

export default TransparentButton

TransparentButton.propTypes = {
  label: PropTypes.string.isRequired,
  url: PropTypes.string,
  btnwidth: PropTypes.string,
  prev: PropTypes.number,
  order: PropTypes.number,
}
